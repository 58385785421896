
import { defineComponent, onActivated, onDeactivated, onUpdated } from 'vue';
import { useInject } from '@/utils/inject';
import { DataItem } from '@/types/app.global';
import SearchTable from '@/components/table/SearchTable.vue';

/**
 * 配货记录，与采购记录一一对应
 */
export default defineComponent({
  name: 'Distribution',
  components: { SearchTable },
  setup() {
    const { https, mapper, constants } = useInject();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'no',
              label: '配货单号'
            },
            {
              field: 'orderCode',
              label: '订单号'
            },
            {
              field: 'status',
              label: '状态',
              type: 'select',
              options: mapper?.mall.distributionStatus
            },
            {
              field: 'phone',
              label: '手机号'
            },
            {
              field: 'alias',
              label: '用户名'
            },
          ]
        },
        remote: {
          action: https?.mall.pageDistribution
        }
      },
      cols: [
        {
          field: 'no',
          label: '配货单号',
          ability: 'copy'
        },
        {
          field: 'orderCode',
          label: '订单号',
          ability: 'copy'
        },
        {
          field: 'statusDesc',
          label: '状态',
          width: 120
        },
        {
          field: 'name',
          label: '姓名',
          width: 120
        },
        {
          field: 'alias',
          label: '用户名',
          width: 120
        },
        {
          field: 'phone',
          label: '手机号',
          width: 120
        },
        {
          field: 'remark',
          label: '备注',
          width: 160
        },
        {
          field: 'createTime',
          label: '创建时间',
          width: 160
        },
        {
          field: 'updateTime',
          label: '更新时间',
          width: 160
        }
      ],
      builtinCmd: {
        nav: { label: '配货', page: constants?.page.distributionDetail, evaluate: (data: DataItem) => ({ no: data.no }) }
      }
    };

    onActivated(() => {
      console.log('actives...');
    });

    onUpdated(() => {
      console.log('update...');
    });

    onDeactivated(() => {
      console.log('onDeactivated...');
    });

    return { tableOpts };
  }
});
